// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--open
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action > i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #147dbf !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #147dbf !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn > i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon > i {
	color: #147dbf !important;
}

body a:hover {
	color: #147dbf !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu > a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu > a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.btn-app-color {
	border-color: #147dbf !important;
	background-color: white !important;
	color: #147dbf !important;
}

.btn-app-color:hover {
	border-color: #147dbf !important;
	background-color: #147dbf !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard > i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard > h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard > p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje > .kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	padding: 15px 10px 15px 10px !important;
	color: #444 !important;
	border-radius: 0 !important;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	border-radius: 0px !important;
	border-top: 3px solid #147dbf !important;
	font-weight: 500 !important;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 25px 25px 25px !important;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #147dbf !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #147dbf !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	background-color: #147dbf !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.btn-filtros {
	margin-right: 5px;
}

.card-kanban {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 15px 10px;
	width: 230px;
}

.pedido-reverso {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
}

.card-kanban p {
	margin: 0;
	color: #0a0a0e;
}

.tag-kanban {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px 4px 8px !important;
	border-radius: 20px;
	gap: 4px;
	min-width: 80px;
}

.tag-kanban p,
.tag-kanban i {
	color: #282831;
	font-size: 12px !important;
	font-weight: 600;
	line-height: 18px;
}

.tag-kanban div {
	width: 4px;
	height: 4px;
	border-radius: 2px;
}

.kanban-datas {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.kanban-icone {
	color: #b2b2bb;
	font-size: 10px;
}

.enviado-por {
	color: #0a0a0e;
}

.background-normal {
	background-color: #eaeaef;
}

.background-em-dia {
	background-color: #caf6e1;
}

.background-em-breve {
	background-color: #f8eabc;
}

.background-atrasado {
	background-color: #f8e0d9;
}

.ponto-normal {
	background-color: #282831;
}

.ponto-em-dia {
	background-color: #0b8149;
}

.ponto-em-breve {
	background-color: #dc9036;
}

.ponto-atrasado {
	background-color: #a80000;
}

.container-kanban {
	display: flex;
	flex-direction: row;
	gap: 24px;
	overflow: auto;
	height: calc(100vh - 204px) !important;
	padding: 0 20px 20px 20px;
	-webkit-overflow-scrolling: touch;
}

.coluna-kanban {
	min-width: 270px;
	background-color: #eaeaef;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.kanban-corpo {
	overflow-y: auto;
}

.kanban-title {
	margin-bottom: 10px;
	color: #0a0a0e;
	font-weight: 600;
	font-size: 13px;
	margin-top: 20px;
}

.container-fase {
	background-color: #eaeaef;
	padding: 4px 8px 4px 8px !important;
	border-radius: 14px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	font-size: 12px !important;
	color: #282831;
	font-weight: 500 !important;
}

.add-imagem {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.btn-remover-imagem {
	position: absolute;
	width: 25px;
	height: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 99;
}

.btn-remover-imagem i {
	font-size: 15px !important;
}

.imagem-upload {
	max-height: 300px;
	max-width: 400px;
	min-width: 80px;
	width: 100%;
}

.check-informacao {
	padding: 5px;
	border-radius: 50%;
	background-color: #caf6e1;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.check-informacao i {
	color: #0b8149;
}

.minus-informacao {
	padding: 5px;
	border-radius: 50%;
	background-color: #f9f2da;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.minus-informacao i {
	color: #9e5f00;
}

.campo-edicao {
	width: 100%;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	padding: 5px;
	min-height: 33px;
}

.box-lembrete {
	border: 1px dashed #595959;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
	gap: 8px;
	background-color: #f5f5f5;
}

.titulo-lembrete {
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #000000;
	margin: 0px;
}

.descricao-lembrete {
	margin: 0px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #595959;
}

.button-margin-right {
	margin-right: 8px;
}

.zero-padding {
	padding: 0 !important;
}

.not-padding {
	padding-right: 0px;
	padding-left: 0px;
	margin-right: 15px;
	margin-left: 15px;
}

.form-view {
	margin-bottom: 20px;
}

.form-view h5,
.text-check {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #000000;
	margin: 0;
}

.form-view p {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #000000 !important;
}

.box-avaliacoes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}

.box-avaliacao {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid #eaeaef;
	border-radius: 4px;
	padding: 20px 20px;
	flex: 1;
	cursor: pointer;
}

.box-avaliacao img {
	width: 32px;
	height: 32px;
}

.avaliacao-check {
	border: 1px solid #9e5f00;
	background-color: #f9f2da;
}

.error-check {
	margin-top: -10px !important;
	margin-bottom: 10px !important;
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.box-total {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	width: 100%;
	margin-right: 10px;
}

.p-datatable-tbody td {
	word-break: break-all;
	white-space: wrap;
	text-overflow: ellipsis;
}

body .p-datatable .p-datatable-header {
	height: auto;
}

@media screen and (max-width: 1024px) {
	.container-kanban {
		height: calc(100vh - 168px) !important;
	}
}

@media screen and (max-width: 632px) {
	body .p-datatable .p-datatable-header {
		padding: 0px;
	}

	.table-header-search {
		width: 100%;
		padding: 0px 12px;
	}
}

@media screen and (max-width: 500px) {
	.box-total {
		margin: 0;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (max-width: 460px) {
	.box-avaliacoes {
		flex-direction: column;
	}
}

@media screen and (max-width: 450px) {
	.container-button {
		display: flex;
		flex-direction: column-reverse;
	}

	.button-margin-right {
		margin-right: 0px;
	}

	.container-button button,
	.container-button a {
		margin-top: 10px;
	}
}

@media screen and (max-width: 389px) {
	.table-header-search .tableHeaderItem:first-child {
		width: 100%;
	}
}
